import {useState} from 'react';
import Info from './info';
import Message from './message';
import Profile from './profile';

export default () => {
    const [stage, setStage] = useState('info');

    if(stage === "info") return <Info setStage={setStage} />
    if(stage === "message") return <Message setStage={setStage} />
    if(stage === "profile") return <Profile setStage={setStage} />
    return <></>;
}