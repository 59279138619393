import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_failed';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import BlueButton from '../../components/blue-button';

export default () => {
    const {transaction, game, reason, param} = useSelector(Selector);

    if(!transaction || !game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                <div className="title">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1328_6825)">
                            <path d="M12.6619 11.5137C12.7629 11.7637 12.9241 11.9853 13.1315 12.1594C13.309 12.3086 13.5161 12.4195 13.7398 12.4851C13.9634 12.5507 14.1987 12.5696 14.4307 12.5405C19.1727 11.9477 21.3288 8.41331 21.3288 8.41331C22.1156 7.09599 21.6781 5.40446 20.3513 4.63387C19.7373 4.27749 18.9833 4.27726 18.3338 4.40709C18.2686 4.42005 18.201 4.41127 18.1416 4.38213C18.105 4.36474 18.0724 4.34013 18.0458 4.30981C18.0192 4.27949 17.9992 4.24409 17.987 4.20577C17.7868 3.58317 17.4209 2.93289 16.8069 2.57652C15.4802 1.80639 13.7664 2.24963 12.9795 3.56644C12.9794 3.56641 10.8884 7.13848 12.6619 11.5137Z" fill="url(#paint0_linear_1328_6825)"/>
                            <path d="M18.1416 4.38194L13.1318 12.1586L13.1315 12.1591C12.9241 11.9851 12.7629 11.7635 12.6619 11.5135C10.8885 7.1383 12.9795 3.56619 12.9795 3.56619C13.7158 2.33405 15.2636 1.86676 16.5464 2.4427C16.6355 2.48274 16.7225 2.52732 16.8069 2.57626C17.4208 2.93264 17.7868 3.58296 17.9869 4.20552C17.9992 4.24384 18.0192 4.27925 18.0458 4.30958C18.0724 4.33991 18.105 4.36453 18.1416 4.38194Z" fill="url(#paint1_linear_1328_6825)"/>
                            <path d="M12.5511 11.5421C12.584 11.8098 12.5504 12.0817 12.4534 12.3345C12.4525 12.3368 12.452 12.339 12.4509 12.3408C12.3676 12.556 12.2403 12.7521 12.077 12.9168C11.9137 13.0814 11.7179 13.2111 11.502 13.2977C7.06715 15.0784 3.46786 13.0326 3.46786 13.0326C2.38777 12.4053 1.89692 11.167 2.17628 10.018C2.24176 9.74918 2.34763 9.49135 2.49034 9.25315C2.85451 8.64379 3.51387 8.278 4.14482 8.07668C4.20816 8.05638 4.26302 8.01593 4.30083 7.96165C4.32442 7.92869 4.34099 7.89137 4.34955 7.85196C4.35811 7.81254 4.35847 7.77187 4.3506 7.73242C4.22379 7.09084 4.22857 6.34468 4.59273 5.73533C5.3797 4.41851 7.09343 3.97527 8.42016 4.7454C8.42015 4.74536 11.9808 6.85569 12.5511 11.5421Z" fill="url(#paint2_linear_1328_6825)"/>
                            <path d="M12.5529 11.5417C12.5857 11.8094 12.5521 12.0813 12.4552 12.3341C12.4542 12.3364 12.4537 12.3386 12.4526 12.3404C7.52767 14.0438 3.59862 11.7397 3.59862 11.7397C2.91708 11.3318 2.4323 10.7143 2.17799 10.0176C2.24347 9.74877 2.34934 9.49094 2.49205 9.25274C2.85622 8.64338 3.51558 8.27759 4.14653 8.07627C4.20987 8.05597 4.26473 8.01552 4.30254 7.96124C4.32612 7.92828 4.3427 7.89096 4.35126 7.85155C4.35982 7.81213 4.36018 7.77146 4.35231 7.73201C4.2255 7.09043 4.23028 6.34427 4.59444 5.73492C5.38141 4.4181 7.09514 3.97486 8.42187 4.74499C8.42186 4.74494 11.9825 6.85527 12.5529 11.5417Z" fill="url(#paint3_linear_1328_6825)"/>
                            <path d="M12.5513 11.5424C12.5841 11.81 12.5506 12.082 12.4536 12.3347L4.30097 7.9619C4.32456 7.92895 4.34113 7.89162 4.34969 7.85221C4.35825 7.8128 4.35861 7.77212 4.35074 7.73267C4.22393 7.09109 4.22871 6.34493 4.59287 5.73558C5.37984 4.41876 7.09357 3.97553 8.4203 4.74565C8.42029 4.74561 11.981 6.85594 12.5513 11.5424Z" fill="url(#paint4_linear_1328_6825)"/>
                            <path d="M18.1415 4.38222L13.1317 12.1588C12.1545 7.5756 14.1803 4.11463 14.1803 4.11463C14.7168 3.21749 15.5902 2.63823 16.5462 2.443C16.6354 2.48303 16.7223 2.52761 16.8067 2.57656C17.4207 2.93293 17.7866 3.58325 17.9868 4.20581C17.999 4.24413 18.0191 4.27953 18.0457 4.30986C18.0723 4.34018 18.1049 4.3648 18.1415 4.38222Z" fill="url(#paint5_linear_1328_6825)"/>
                            <path d="M14.4754 12.615C14.222 12.7165 13.9969 12.8773 13.8196 13.0834C13.6676 13.2599 13.5542 13.4654 13.4865 13.687C13.4187 13.9086 13.3982 14.1413 13.4262 14.3706C13.9964 19.0565 17.5571 21.1669 17.5571 21.1669C18.8843 21.9369 20.5976 21.4938 21.385 20.1768C21.7492 19.5675 21.7541 18.8218 21.6268 18.1803C21.6141 18.1159 21.6234 18.049 21.6532 17.9901C21.671 17.9538 21.6961 17.9214 21.7269 17.8949C21.7577 17.8684 21.7937 17.8484 21.8325 17.836C22.4633 17.6343 23.1232 17.2683 23.4873 16.659C24.2743 15.3422 23.8367 13.6501 22.5099 12.88C22.51 12.88 18.9108 10.8342 14.4754 12.615Z" fill="url(#paint6_linear_1328_6825)"/>
                            <path d="M21.652 17.9904L13.8191 13.0841L13.8185 13.0837C13.9958 12.8776 14.2208 12.7168 14.4743 12.6153C18.9095 10.8345 22.5088 12.8803 22.5088 12.8803C23.7503 13.6009 24.2132 15.1287 23.6229 16.4009C23.5819 16.4892 23.5362 16.5755 23.4862 16.6593C23.1221 17.2686 22.4622 17.6346 21.8314 17.8363C21.7926 17.8487 21.7566 17.8687 21.7258 17.8952C21.6949 17.9216 21.6699 17.954 21.652 17.9904Z" fill="url(#paint7_linear_1328_6825)"/>
                            <path d="M21.6542 17.9898L13.8213 13.0835C18.462 12.0888 21.9493 14.0708 21.9493 14.0708C22.8532 14.5959 23.4336 15.456 23.6251 16.4002C23.584 16.4886 23.5384 16.5749 23.4884 16.6587C23.1242 17.268 22.4644 17.6339 21.8336 17.8357C21.7947 17.8481 21.7588 17.8681 21.728 17.8946C21.6971 17.9211 21.6721 17.9535 21.6542 17.9898Z" fill="url(#paint8_linear_1328_6825)"/>
                            <path d="M13.2922 14.4053C13.1911 14.1553 13.0299 13.9337 12.8225 13.7597C12.645 13.6105 12.4379 13.4995 12.2143 13.4339C11.9906 13.3683 11.7553 13.3495 11.5234 13.3786C6.78132 13.9714 4.62526 17.5057 4.62526 17.5057C3.83843 18.823 4.27592 20.5146 5.60278 21.2852C6.21672 21.6415 6.97075 21.6418 7.62019 21.5119C7.68543 21.499 7.75304 21.5078 7.81242 21.5369C7.84903 21.5543 7.88162 21.5789 7.90822 21.6093C7.93481 21.6396 7.95485 21.675 7.96709 21.7133C8.16725 22.3359 8.53319 22.9862 9.14714 23.3426C10.4739 24.1127 12.1876 23.6695 12.9746 22.3526C12.9746 22.3526 15.0655 18.7806 13.2922 14.4053ZM14.217 14.0231C14.2239 13.8539 14.2435 13.7705 14.2629 13.9282C14.2684 13.9737 14.2499 14.0032 14.217 14.0231Z" fill="url(#paint9_linear_1328_6825)"/>
                            <path d="M7.81147 21.5369L12.8212 13.7603L12.8216 13.7597C13.0289 13.9338 13.1901 14.1553 13.2912 14.4053C15.0646 18.7806 12.9736 22.3527 12.9736 22.3527C12.2372 23.5848 10.6894 24.0521 9.40665 23.4762C9.31752 23.4361 9.23058 23.3916 9.14616 23.3426C8.53222 22.9862 8.16626 22.3359 7.96612 21.7134C7.95389 21.675 7.93386 21.6396 7.90728 21.6093C7.88069 21.5789 7.84809 21.5543 7.81147 21.5369Z" fill="url(#paint10_linear_1328_6825)"/>
                            <path d="M7.81135 21.537L12.8211 13.7603C13.7983 18.3436 11.7725 21.8045 11.7725 21.8045C11.236 22.7017 10.3626 23.2809 9.40658 23.4762C9.31745 23.4361 9.2305 23.3916 9.14609 23.3426C8.53214 22.9862 8.16619 22.3359 7.96604 21.7134C7.95379 21.675 7.93376 21.6396 7.90717 21.6093C7.88057 21.579 7.84797 21.5544 7.81135 21.537Z" fill="url(#paint11_linear_1328_6825)"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1328_6825" x1="13.2663" y1="16.012" x2="27.7846" y2="10.4494" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1328_6825" x1="13.1389" y1="14.9974" x2="23.6086" y2="11.2884" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1328_6825" x1="3.57197" y1="18.5191" x2="18.0901" y2="12.9565" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_1328_6825" x1="3.66051" y1="17.5061" x2="17.6773" y2="12.0539" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_1328_6825" x1="6.24724" y1="16.7801" x2="16.7171" y2="13.0711" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_1328_6825" x1="13.4776" y1="14.7207" x2="23.3871" y2="11.1961" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_1328_6825" x1="15.7583" y1="25.5488" x2="30.2772" y2="19.9858" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_1328_6825" x1="15.6261" y1="21.7449" x2="28.7758" y2="16.2074" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_1328_6825" x1="15.6275" y1="21.6282" x2="27.9171" y2="16.4825" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_1328_6825" x1="5.99801" y1="28.0739" x2="21.8386" y2="21.8068" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_1328_6825" x1="8.91034" y1="27.0787" x2="19.3801" y2="23.3697" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_1328_6825" x1="8.72856" y1="26.3079" x2="18.6381" y2="22.7833" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79EE73"/>
                                <stop offset="1" stop-color="#4FB1A1"/>
                            </linearGradient>
                            <clipPath id="clip0_1328_6825">
                                <rect width="19.893" height="19.893" fill="white" transform="translate(0.879883 5.86084) rotate(-14.5012)"/>
                            </clipPath>
                        </defs>
                        </svg>

                        Test Your Luck
                    </div>
                    <div className="descriptions">Bonus transfer has been cancelled, you can see the reason below</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="tx-amount"><span>$</span>{transaction.amount}</div>
                </div>

                <div className="tx-details">
                    <div className="label">Transaction ID</div>
                    <CopyToClipboard text={`Test Your Luck transaction ${transaction.transactionId} for the amount $${transaction.amount} to login ${transaction.login} in ${game.name}`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="value">
                            {transaction.transactionId} 
                        
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                {reason === 'user_in_bonus' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Your gaming account is in a bonus session. Complete the bonus session and try to load bonus again...</span>
                    </div>
                )}

                {reason === 'store_balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>At the moment, we cannot load bonus for this amount. Select another game to load a bonus.</span>
                    </div>
                )}

                {reason === 'system' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Funds could not be credited to your game login. Try again later... [<b>{param}</b>]</span>
                    </div>
                )}

                {reason === 'balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>You cannot load bonus into this game because <b>your game balance is more than $2</b>.</span>
                    </div>
                )}

                {reason === 'user_in_game' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Log out of the game and try to make load bonus again.</span>
                    </div>
                )}
            </div>

            <div className="modal-footer">
                {['balance', 'user_in_bonus', 'user_in_game'].includes(reason) && (
                    <BlueButton onClick={e => ModalsLib.open('changeGame', {
                        handler: game => ModalsLib.open('testyourluck_add', {game})
                    })}>
                        Change game
                    </BlueButton>
                )}

                {['store_balance', 'system'].includes(reason) && (
                    <BlueButton onClick={e => window.open('https://t.me/casinos_support_bot', '_blank')}>
                        Live operator
                    </BlueButton>
                )}

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}