import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.facebook_profile,
    (profile) => {
        return {
            profile
        }
    }
)