import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/modals/facebook';

import Pending from './pending';
import Approved from './approved';

export default () => {
    const {profile} = useSelector(Selector);

    if(profile.status === 0)
        return <Pending />;

    return <Approved />;
}