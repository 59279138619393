import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/auth/signup';
import {signUp} from '../../../reducers/modals/actions';
import BlueButton from '../../components/blue-button';
import FormInput from '../../components/form-input';
import { useState } from 'react';
import ModalsLib from '../../../lib/modals';

export default () => {
    const {data, referral_bonus, affiliate_bonus} = useSelector(Selector),
          dispatch = useDispatch();

    const [phone, setPhone] = useState(""),
          [email, setEmail] = useState(""),
          [username, setUsername] = useState(""),
          [password, setPassword] = useState(""),
          [checkbox, setCheckbox] = useState(false);

    return (
        <div className="modal auth animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Sign Up</div>
                    <div className="descriptions">Fill in the fields below and join Lucky!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <FormInput
                            name="phone"
                            type={'tel'}
                            label={null}
                            placeholder={'(198) 765 43-21'}
                            defaultValue={phone}
                            isPhone={true}
                            validators={[
                                (value) => {
                                    value = value.replace(/[^0-9]/gi, '');
                                    return value.length === 10;
                                }
                            ]}
                            onChange={({value, validator}) => setPhone(value.replace(/[^0-9]/gi, ''))} />

                    <FormInput
                        name="email"
                        type={'text'}
                        label={null}
                        placeholder={'Email'}
                        defaultValue={email}
                        validators={[
                            (value) => {
                                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                return regex.test(value);
                            }
                        ]}
                        onChange={({value, validator}) => setEmail(value)} />

                    <FormInput
                        name="username"
                        type={'text'}
                        label={null}
                        placeholder={'Username'}
                        defaultValue={username}
                        validators={[
                            (value) => value.replace(/[^a-z0-9_]/gi, '').length > 6
                        ]}
                        onChange={({value, validator}) => setUsername(value.replace(/[^a-z0-9_]/gi, ''))} />

                    <FormInput
                        name="password"
                        type={'password'}
                        label={null}
                        placeholder={'Password'}
                        defaultValue={password}
                        validators={[
                            value => value.length > 7,
                            value => value.replace(/[^a-z]/gi, '').length > 0,
                            value => value.replace(/[^0-9]/gi, '').length > 0
                        ]}
                        onChange={({value, validator}) => setPassword(value)} />

                    <div className="checkbox">
                        <div className={`check ${checkbox ? 'active' : ''}`} onClick={e => setCheckbox(!checkbox)}>
                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 5L4.99448 9L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="text">
                            I confirm that I have read and fully agree with <a href="#" target="_blank">Conditions of the Casinos website User Agreement</a>
                        </div>
                    </div>

                    <div className="bonuses">
                        {(referral_bonus || affiliate_bonus) && (
                            <>
                                {affiliate_bonus ? (
                                    <div className="bonus">
                                        <div className="left">
                                            <img src={`${window.location.origin}/source/system/auth/gamepad.png`} alt="" />
                                            {affiliate_bonus}
                                        </div>
                                        <div className="right">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="bonus">
                                            <div className="left">
                                                <img src={`${window.location.origin}/source/system/auth/cash.png`} alt="" />
                                                Income bonus ${referral_bonus}
                                            </div>
                                            <div className="right">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <hr />
                            </>
                        )}
                        <div className="bonus">
                            <div className="left">
                                <img src={`${window.location.origin}/source/system/auth/gift.png`} alt="" />
                                First deposit bonus +50%
                            </div>
                            <div className="right">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <hr />
                        <div className="bonus">
                            <div className="left">
                                <img src={`${window.location.origin}/source/system/auth/slot.png`} alt="" />
                                Cashback up to 30%
                            </div>
                            <div className="right">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                    {data.error && (
                        <div class="modal-message warning">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span dangerouslySetInnerHTML={{__html: data.error}}></span>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal-footer">
                <BlueButton active={data.button} onClick={e => {
                    dispatch(signUp({
                        phone,
                        email,
                        username,
                        password,
                        checkbox
                    }));
                }}>
                    Sign Up
                </BlueButton>
                <div className="auth-text">
                    Already have an account? <span onClick={e => ModalsLib.open('signin')}>Sign In</span>
                </div>
            </div>
        </div>
    )
}