export default {
    loaded: 'user.loaded',
    game: 'user.game',
    balance: 'user.balance',
    pending_voucher: "user.pending_voucher",
    update: "user.update",
    vip: {
        xp: 'user.vip.xp'
    },
    logins: {
        create: "user.logins.create",
        update: "user.logins.update",
        remove: "user.logins.remove"
    },
    facebook_profile: {
        toggle: "user.facebook_profile.toggle.button",
        update: "user.facebook_profile.update"
    },
    logout: "user.logout"
}