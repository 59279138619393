import types from './types';

const InitialState = {
    loaded: false,
    user: null,
    logins: null,
    pending_voucher: null,
    facebook_profile: null
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.facebook_profile.toggle:
            return {
                ...state,
                facebook_profile: {
                    ...state.facebook_profile,
                    button: !state.facebook_profile.button
                }
            }
        case types.facebook_profile.update:
            return {
                ...state,
                facebook_profile: {
                    ...payload,
                    button: false
                }
            }
        case types.update:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload
                }
            }
        case types.logout:
            return {
                ...state,
                user: null,
                logins: [],
                pending_voucher: null
            }
        case types.pending_voucher:
            return {
                ...state,
                pending_voucher: payload
            }
        case types.vip.xp:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    xp: payload
                } : state.user
            }
        case types.game:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    game: payload
                } : state.user
            }
        case types.balance:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    balance: payload.balance,
                    wager: payload.wager
                } : state.user
            }
        case types.logins.create:
            return {
                ...state,
                logins: state.logins ? [
                    {
                        ...payload,
                        balance_details: {
                            ...payload.balance_details,
                            lastUpdateTimestamp: payload.balance_details.lastUpdateTimestamp + Date.now()
                        }
                    },
                    ...state.logins
                ] : state.logins
            }
        case types.logins.remove:
            return {
                ...state,
                logins: state.logins ? state.logins.filter(login => login.game !== payload) : state.logins
            }
        case types.logins.update:
            return {
                ...state,
                logins: state.logins ? state.logins.map(login => {
                    return login.id === payload.id ? {
                        ...login,
                        ...payload,
                        balance_details: payload.balance_details ? {
                            ...payload.balance_details,
                            lastUpdateTimestamp: payload.balance_details.lastUpdateTimestamp + Date.now()
                        } : login.balance_details
                    } : login;
                }) : state.logins
            }
        case types.game:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    game: payload
                } : state.user
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                user: payload.user,
                pending_voucher: payload.pending_voucher,
                logins: payload.logins.map(login => {
                    return {
                        ...login,
                        balance_details: {
                            ...login.balance_details,
                            lastUpdateTimestamp: login.balance_details.lastUpdateTimestamp + Date.now()
                        }
                    }
                }),
                facebook_profile: {
                    ...payload.facebook_profile,
                    button: false
                }
            }
        default:
            return state;
    }
}