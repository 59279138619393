import BlueButton from "../../../components/blue-button"
import ModalsLib from '../../../../lib/modals';

export default ({setStage}) => {
    return (
        <>
            <div className="modal-header no-post">
                <div className="title"></div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <img className="facebook-image" src={`${window.location.origin}/source/system/slider/facebook/facebook-image.png`} alt="" />
                <div className="text">
                    Your post has passed moderation and the following bonuses are available to you:
                </div>
                <div className="bonuses">
                    <div className="bonus">
                        <div className="left">
                            <img src={`${window.location.origin}/source/system/auth/gamepad.png`} alt="" />
                            Personal freeplays
                        </div>
                        <div className="right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <hr />
                    <div className="bonus">
                        <div className="left">
                            <img src={`${window.location.origin}/source/system/auth/balance-emoji.png`} alt="" />
                            $1 every 24 hours to your balance
                        </div>
                        <div className="right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <hr />
                    <div className="bonus">
                        <div className="left">
                            <img src={`${window.location.origin}/source/system/auth/gift.png`} alt="" />
                            +5% to each deposit
                        </div>
                        <div className="right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <hr />
                    <div className="bonus">
                        <div className="left">
                            <img src={`${window.location.origin}/source/system/auth/cashback-emoji.png`} alt="" />
                            +5% VIP Cashback (Weekly)
                        </div>
                        <div className="right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <hr />
                    <div className="bonus">
                        <div className="left">
                            <img src={`${window.location.origin}/source/system/auth/money-emoji.png`} alt="" />
                            +5% Referral bonus
                        </div>
                        <div className="right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.95834 2.04166C9.53334 1.55 10.475 1.55 11.0583 2.04166L12.375 3.175C12.625 3.39166 13.0917 3.56666 13.425 3.56666H14.8417C15.725 3.56666 16.45 4.29166 16.45 5.175V6.59166C16.45 6.91666 16.625 7.39166 16.8417 7.64166L17.975 8.95833C18.4667 9.53333 18.4667 10.475 17.975 11.0583L16.8417 12.375C16.625 12.625 16.45 13.0917 16.45 13.425V14.8417C16.45 15.725 15.725 16.45 14.8417 16.45H13.425C13.1 16.45 12.625 16.625 12.375 16.8417L11.0583 17.975C10.4833 18.4667 9.54167 18.4667 8.95834 17.975L7.64167 16.8417C7.39167 16.625 6.925 16.45 6.59167 16.45H5.15C4.26667 16.45 3.54167 15.725 3.54167 14.8417V13.4167C3.54167 13.0917 3.36667 12.625 3.15834 12.375L2.03334 11.05C1.55 10.475 1.55 9.54166 2.03334 8.96666L3.15834 7.64166C3.36667 7.39166 3.54167 6.925 3.54167 6.6V5.16666C3.54167 4.28333 4.26667 3.55833 5.15 3.55833H6.59167C6.91667 3.55833 7.39167 3.38333 7.64167 3.16666L8.95834 2.04166Z" fill="#4FACFE" stroke="#4FACFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.9834 10L8.99173 12.0167L13.0167 7.98334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>  
                </div>

                <div className="modal-message success">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Bonuses are awarded automatically upon deposits and referral payments. The $1 bonus is credited every 24 hours if there is a post on Facebook.</span>
                </div>

                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>Free games will soon be available in the Free Cash section.</span>
                </div>
            </div>

            <div className="modal-footer">
                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </>
    )
}