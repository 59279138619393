import types from './types';
import API from '../../lib/api';
import ModalsLib from '../../lib/modals';
import {isSupported, requestForToken} from '../../lib/firebase';
import {toast} from 'react-toastify';

export const subscribePushNotifications = (afterSubscribe = () => {}) => async dispatch => {
    dispatch({type: types.notifications.button});

    const sup = await isSupported();

    if(!sup) {
        toast.dismiss();
        toast.error(`This browser doesn't support Push Notifications`);
        return dispatch({type: types.notifications.button});
    }

    const notificationsPermissions = ('Notification' in window) ? await Notification.requestPermission() : null;

    if(notificationsPermissions !== 'granted') {
        toast.dismiss();
        toast.error(`You have not given permission to display notifications.`);
        return dispatch({type: types.notifications.button});
    }

    const token = await requestForToken();

    if(token)
        await API.call({
            path: '/push/authorize',
            body: {
                token
            }
        });

    dispatch({
        type: types.notifications.update,
        payload: {
            registered: true
        }
    });

    // Send token to RESt
    toast.dismiss();
    toast.success(`Push notifications has been enabled!`);

    afterSubscribe();

    dispatch({type: types.notifications.button});
}

export const initializePushNotifications = () => async dispatch => {
    const supported = await isSupported();

    if(!supported)
        return dispatch({
            type: types.notifications.init,
            payload: {
                supported: false,
                registered: false
            }
        });

    let fcmToken = null;

    const permission = ('Notification' in window) ? Notification.permission === 'granted' : null;
    if(permission === 'granted')
        fcmToken = await requestForToken();

    return dispatch({
        type: types.notifications.init,
        payload: {
            supported: true,
            registered: fcmToken ? true : false
        }
    });
}

export const initialize = () => async dispatch => {
    const response = await API.call({
        path: '/initialize',
        body: {
            referral: new URLSearchParams(window.location.search).get('r') || "",
            affiliate: new URLSearchParams(window.location.search).get('a') || ""
        }
    });

    if(response.success) {
        dispatch({
            type: types.init,
            payload: {
                referral_bonus: response.referral_bonus,
                affiliate_bonus: response.affiliate_bonus
            }
        });

        if(response.affiliate_bonus)
            setTimeout(() => {
                ModalsLib.open('signup');
            }, 2e3);
    }
}

export const setConnection = connection => {
    return {
        type: types.connection,
        payload: connection
    }
}

export const setMobile = state => {
    return {
        type: types.mobile,
        payload: state
    }
}

export const toggleSidebar = () => {
    return {
        type: types.sidebar
    }
}