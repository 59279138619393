import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/facebook';

import HasPost from './has-post';
import NoPost from './no-post';

export default () => {
    const {profile} = useSelector(Selector);

    return (
        <div className="modal auth facebook">
            {
                profile.exist
                ? <HasPost />
                : <NoPost />
            }
        </div>
    )
}