import types from './types';
import globalTypes from '../types';
import API from '../../lib/api';
import {toast} from 'react-toastify';
import ModalsLib from '../../lib/modals';
import WS from '../../lib/websocket';
import { navigate } from '../../utils/navigation';
import {requestForToken} from '../../lib/firebase';

export const set = modal => {
    return {
        type: types.set,
        payload: modal
    }
}

export const updateData = (slug, data) => {
    return {
        type: types.update,
        payload: {
            slug,
            data
        }
    }
}

export const resetModal = slug => {
    return {
        type: types.reset,
        payload: slug
    }
}


// Wallet
export const setWalletPreset = ({
    tab,
    mode,
    method
}) => {
    return {
        type: types.wallet.preset,
        payload: {
            tab,
            mode,
            method
        }
    }
}

export const setWalletTab = tab => {
    return {
        type: types.wallet.tab,
        payload: tab
    }
}

export const signUp = data => async dispatch => {
    dispatch({
        type: types.update,
        payload: {
            slug: "signup",
            data: {
                ...data,
                error: "",
                button: true
            }
        }
    });

    const response = await API.call({
        path: '/auth/signup',
        body: {
            phone: data.phone,
            email: data.email,
            username: data.username,
            password: data.password,
            checkbox: data.checkbox ? 1 : 0
        }
    });

    if(!response.success) {
        dispatch({
            type: types.update,
            payload: {
                slug: "signup",
                data: {
                    button: false,
                    error: response.error
                }
            }
        });
    } else {
        ModalsLib.close();

        // Запрашиваем пользователя
        const userResponse = await API.call({
            path: '/auth/user/get'
        });

        if(!userResponse.success) {
            toast.error(userResponse.error);
            window.location.reload();
            return;
        } else {
            toast.success(response.message); // Welcome message
        }

        if(userResponse.user)
            WS.authorize(userResponse.user.userId, userResponse.user.userStringId);

        dispatch({
            type: globalTypes.user.loaded,
            payload: {
                user: userResponse.user,
                logins: userResponse.logins,
                pending_voucher: userResponse.pending_voucher ? {
                    ...userResponse.pending_voucher,
                    expire_at: Date.now() + userResponse.pending_voucher.expire_at
                } : userResponse.pending_voucher,
                facebook_profile: userResponse.facebook_profile
            }
        });

        if(response.affiliate_bonus)
            ModalsLib.open('affiliate_freeplay');
    }
}

export const signIn = data => async dispatch => {
    dispatch({
        type: types.update,
        payload: {
            slug: "signin",
            data: {
                ...data,
                error: "",
                button: true
            }
        }
    });

    const response = await API.call({
        path: '/auth/signin',
        body: {
            username: data.username,
            password: data.password
        }
    });

    if(!response.success) {
        dispatch({
            type: types.update,
            payload: {
                slug: "signin",
                data: {
                    button: false,
                    error: response.error
                }
            }
        });
    } else {
        ModalsLib.close();

        // Запрашиваем пользователя
        const userResponse = await API.call({
            path: '/auth/user/get'
        });

        if(!userResponse.success) {
            toast.error(userResponse.error);
            window.location.reload();
            return;
        } else {
            toast.success(response.message); // Welcome message
        }

        if(userResponse.user)
            WS.authorize(userResponse.user.userId, userResponse.user.userStringId);

        dispatch({
            type: globalTypes.user.loaded,
            payload: {
                user: userResponse.user,
                logins: userResponse.logins,
                pending_voucher: userResponse.pending_voucher ? {
                    ...userResponse.pending_voucher,
                    expire_at: Date.now() + userResponse.pending_voucher.expire_at
                } : userResponse.pending_voucher,
                facebook_profile: userResponse.facebook_profile
            }
        });

        // Authorize push token
        if(Notification.permission === 'granted') {
            const token = await requestForToken();

            if(token)
                await API.call({
                    path: '/push/authorize',
                    body: {
                        token
                    }
                });
        }
    }
}

export const completeProfile = (data, game) => async dispatch => {
    dispatch({
        type: types.update,
        payload: {
            slug: "profile_complete",
            data: {
                ...data,
                error: "",
                button: true
            }
        }
    });

    const response = await API.call({
        path: '/auth/user/completeProfile',
        body: data
    });

    if(!response.success) {
        dispatch({
            type: types.update,
            payload: {
                slug: "profile_complete",
                data: {
                    button: false,
                    error: response.error
                }
            }
        });
    } else {
        toast.dismiss();
        toast.success(response.message);

        setTimeout(() => {
            dispatch({
                type: types.update,
                payload: {
                    slug: "profile_complete",
                    data: {
                        button: false
                    }
                }
            });

            dispatch({
                type: globalTypes.user.update,
                payload: response.data
            });
        }, 500);

        ModalsLib.open('addcash', {game});
    }
}

export const redeemFreeplay = activationId => async dispatch => {
    dispatch({
        type: types.update,
        payload: {
            slug: "freeplay_add",
            data: {
                error: "",
                button: true
            }
        }
    });

    const response = await API.call({
        path: '/freecash/freeplay',
        body: {
            activationId
        }
    });

    if(!response.success) {
        dispatch({
            type: types.update,
            payload: {
                slug: "freeplay_add",
                data: {
                    button: false,
                    error: response.error
                }
            }
        });
    } else {
        dispatch({
            type: globalTypes.games.history.add,
            payload: response.transaction
        });

        dispatch({
            type: types.update,
            payload: {
                slug: "freeplay_add",
                data: {
                    button: false,
                }
            }
        });
        
        toast.dismiss();
        toast.success(response.message);

        ModalsLib.close();
    }
}