import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_failed';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import BlueButton from '../../components/blue-button';

export default () => {
    const {transaction, game, reason, param} = useSelector(Selector);

    if(!transaction || !game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.14536 4.52085H5.99953C5.93084 4.51805 5.86375 4.49932 5.80355 4.46614C5.74335 4.43296 5.69169 4.38624 5.65265 4.32966C5.61361 4.27308 5.58827 4.2082 5.57861 4.14014C5.56896 4.07209 5.57526 4.00272 5.59703 3.93751C5.80119 3.42418 5.98786 2.89918 6.13953 2.38585C6.26786 1.98918 6.76953 0.74668 8.02953 0.74668C8.52059 0.730945 8.99784 0.910667 9.35655 1.24641C9.71526 1.58215 9.92612 2.04648 9.94286 2.53751C9.94286 3.66335 8.66536 4.12418 8.19286 4.28751C7.52485 4.46183 6.83546 4.54039 6.14536 4.52085ZM8.02369 1.60418C7.35286 1.60418 7.04953 2.38001 6.97369 2.61918C6.86869 2.95751 6.75786 3.29585 6.63536 3.63418C7.05356 3.63152 7.46951 3.57266 7.87203 3.45918C8.20453 3.34251 9.03869 3.02751 9.03869 2.52001C9.02072 2.26575 8.90497 2.02833 8.71573 1.85758C8.52648 1.68682 8.27846 1.596 8.02369 1.60418Z" fill="white"/>
                            <path d="M5.83677 4.52082C5.16167 4.53271 4.48822 4.45025 3.83594 4.27582C2.38344 3.80332 2.08594 3.06249 2.08594 2.52582C2.08838 2.29915 2.13666 2.07531 2.22789 1.86779C2.31912 1.66027 2.4514 1.47336 2.61677 1.31832C2.9845 0.948381 3.4835 0.738717 4.0051 0.734985C4.9851 0.734985 5.48094 1.65082 5.84844 2.30415C6.08726 2.83973 6.28234 3.39376 6.43177 3.96082C6.44984 4.02517 6.45293 4.0928 6.44079 4.15853C6.42866 4.22426 6.40163 4.28633 6.36177 4.33999C6.32313 4.39417 6.27249 4.43868 6.2138 4.47005C6.1551 4.50142 6.08996 4.5188 6.02344 4.52082H5.83677ZM3.94094 1.60415C3.66392 1.61608 3.40242 1.73533 3.21177 1.93665C3.12862 2.00998 3.06139 2.09957 3.01423 2.19991C2.96708 2.30024 2.941 2.40918 2.9376 2.51999C2.9376 2.87582 3.36927 3.21999 4.10427 3.45915C4.53315 3.57001 4.97383 3.62877 5.41677 3.63415C5.32242 3.3235 5.20744 3.01949 5.0726 2.72415C4.73427 2.07665 4.4076 1.61582 3.95844 1.60415H3.94094Z" fill="white"/>
                            <path d="M11.3952 5.97921V5.25004C11.3952 4.82459 11.2262 4.41656 10.9253 4.11572C10.6245 3.81488 10.2165 3.64587 9.79102 3.64587H2.20768C1.78223 3.64587 1.3742 3.81488 1.07337 4.11572C0.772526 4.41656 0.603516 4.82459 0.603516 5.25004V5.97921H11.3952ZM6.43685 6.85421V13.2709H9.79102C10.2165 13.2709 10.6245 13.1019 10.9253 12.801C11.2262 12.5002 11.3952 12.0922 11.3952 11.6667V6.85421H6.43685ZM5.56185 6.85421H0.603516V11.6667C0.603516 12.0922 0.772526 12.5002 1.07337 12.801C1.3742 13.1019 1.78223 13.2709 2.20768 13.2709H5.56185V6.85421Z" fill="white"/>
                        </svg>

                        Free Play
                    </div>
                    <div className="descriptions">Bonus transfer has been cancelled, you can see the reason below</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="tx-amount"><span>$</span>{transaction.amount}</div>
                </div>

                <div className="tx-details">
                    <div className="label">Transaction ID</div>
                    <CopyToClipboard text={`Freeplay transaction ${transaction.transactionId} for the amount $${transaction.amount} to login ${transaction.login} in ${game.name}`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="value">
                            {transaction.transactionId} 
                        
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                {reason === 'user_in_bonus' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Your gaming account is in a bonus session. Complete the bonus session and try to load bonus again...</span>
                    </div>
                )}

                {reason === 'store_balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>At the moment, we cannot load bonus for this amount. Select another game to load a bonus.</span>
                    </div>
                )}

                {reason === 'system' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Funds could not be credited to your game login. Try again later... [<b>{param}</b>]</span>
                    </div>
                )}

                {reason === 'balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>You cannot load bonus into this game because <b>your game balance is more than $2</b>.</span>
                    </div>
                )}

                {reason === 'user_in_game' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Log out of the game and try to make load bonus again.</span>
                    </div>
                )}
            </div>

            <div className="modal-footer">
                {['store_balance', 'system'].includes(reason) && (
                    <BlueButton onClick={e => window.open('https://t.me/casinos_support_bot', '_blank')}>
                        Live operator
                    </BlueButton>
                )}

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}