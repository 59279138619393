import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/modals/facebook';
import BlueButton from "../../../components/blue-button"
import ModalsLib from '../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {useState} from 'react';
import FormInput from '../../../components/form-input';
import { createFacebookProfile } from '../../../../reducers/user/actions';

export default () => {
    const {profile} = useSelector(Selector),
          [link, setLink] = useState(""),
          dispatch = useDispatch();

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Facebook Boost</div>
                    <div className="descriptions">Attach a link to your profile so that we can monitor the availability of the post.</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <FormInput
                        type={'text'}
                        label='Profile link'
                        placeholder={'https://facebook.com/profile?id=...'}
                        defaultValue={link}
                        onChange={({value, validator}) => {
                            setLink(value);
                        }} />
                </div>
                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>Make sure your profile is <b>public</b> and you have more than <b>30 friends</b></span>
                </div>
            </div>

            <div className="modal-footer">
                <BlueButton active={profile.button} onClick={e => {
                    dispatch(createFacebookProfile(link));
                }}>
                    Complete
                </BlueButton>
            </div>
        </>
    )
}