import {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../../../selectors/pages/home/welcome_bonus';

import Slides from './slides';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from 'swiper/modules'
import 'swiper/css';

export default ({scale}) => {
    const {active} = useSelector(Selector),
          [sliderClass, setSliderClass] = useState("");

    return (
        <div className={`slider ${sliderClass}`} style={{transform: `scale(${scale})`}} >
            <div className="slider-carousel">
                <Swiper 
                    modules={[Pagination, Autoplay]}
                    loop={true}
                    pagination={{
                        el: '.pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<div class="dot ${className}" data-index="${index}"></div>`
                        }
                    }}
                    autoplay={{
                        delay: 7e3
                    }}
                    centeredSlides={true}
                    noSwiping={true}
                    speed={1e3}
                    slidesPerGroup={1}
                    slidesPerView={1}
                    onSlideChangeTransitionStart={(swiper) => {
                        const activeSlide = swiper.slides[swiper.activeIndex];
                        setSliderClass(activeSlide.getAttribute('data-type') || "");
                    }}
                    onInit={(swiper) => {
                        const activeSlide = swiper.slides[swiper.activeIndex];
                        setSliderClass(activeSlide.getAttribute('data-type') || "");
                    }}>
                    {active && <SwiperSlide data-type="welcome"><Slides.WelcomeBonus /></SwiperSlide>}
                    <SwiperSlide data-type="facebook"><Slides.Facebook /></SwiperSlide>
                    <SwiperSlide data-type="wheel"><Slides.Wheel /></SwiperSlide>
                    <SwiperSlide data-type="testyourluck"><Slides.TestYourLuck /></SwiperSlide>
                    <SwiperSlide data-type="facebook"><Slides.Facebook /></SwiperSlide>
                    <SwiperSlide data-type="vip"><Slides.VIP /></SwiperSlide>
                    <SwiperSlide data-type="invite"><Slides.Invite /></SwiperSlide>
                </Swiper>
            </div>
            <div className="pagination-space">
                <div className="pagination">
                    <div className="dot active"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </div>
    )
}