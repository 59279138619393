import types from './types';
import API from '../../lib/api';
import WS from '../../lib/websocket';
import {toast} from 'react-toastify';
import {requestForToken} from '../../lib/firebase';
import ModalsLib from '../../lib/modals';
import globalTypes from '../types';

export const createFacebookProfile = link => async dispatch => {
    dispatch({
        type: types.facebook_profile.toggle
    });

    const response = await API.call({
        path: '/facebook/create',
        body: {
            link
        }
    });

    dispatch({
        type: types.facebook_profile.toggle
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
    } else {
        dispatch({
            type: types.facebook_profile.update,
            payload: response.profile
        });
    }
}

export const setGame = game => {
    return {
        type: types.game,
        payload: game
    }
}

export const getUser = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/get'
    });

    if(!response.success)
        return toast.error(response.error);

    if(response.user)
        WS.authorize(response.user.userId, response.user.userStringId);

    dispatch({
        type: types.loaded,
        payload: {
            user: response.user,
            logins: response.logins,
            pending_voucher: response.pending_voucher ? {
                ...response.pending_voucher,
                expire_at: Date.now() + response.pending_voucher.expire_at
            } : response.pending_voucher,
            facebook_profile: response.facebook_profile
        }
    });

    if(response.user && response.user.type !== 'telegram-webapp') {
        // Authorize push token
        if(('Notification' in window) && Notification.permission === 'granted') {
            const token = await requestForToken();

            if(token)
                await API.call({
                    path: '/push/authorize',
                    body: {
                        token
                    }
                });
        }
    }
}

export const getTelegramUser = query => async dispatch => {
    const response = await API.call({
        path: '/auth/user/telegram',
        body: {
            query
        }
    });

    if(!response.success)
        return toast.error(response.error);

    const userResponse = await API.call({
        path: '/auth/user/get'
    });

    if(!userResponse.success)
        return toast.error(userResponse.error);

    if(userResponse.user)
        WS.authorize(userResponse.user.userId, userResponse.user.userStringId);



    dispatch({
        type: types.loaded,
        payload: {
            user: userResponse.user,
            logins: userResponse.logins,
            pending_voucher: userResponse.pending_voucher ? {
                ...userResponse.pending_voucher,
                expire_at: Date.now() + userResponse.pending_voucher.expire_at
            } : userResponse.pending_voucher,
            facebook_profile: userResponse.facebook_profile
        }
    });

    // Click handler
    const locatonSearch = new URLSearchParams(window.location.search);

    if(locatonSearch.get('voucherRedeem')) {
        const activation_response = await API.call({
            path: '/freecash/promocode',
            method: 'POST',
            body: {
                code: locatonSearch.get('voucherRedeem')
            }
        });

        if(!activation_response.success) {
            toast.dismiss();
            toast.error(activation_response.error);
        } else {
            if(activation_response.details.type === 'freeplay')
                ModalsLib.open('freeplay_add', activation_response.details);

            if(['cash', 'deposit', 'deposit-cash'].includes(activation_response.details.type)) {
                toast.dismiss();
                toast.success(activation_response.message);

                if(['deposit', 'deposit-cash'].includes(activation_response.details.type)) {
                    dispatch({
                        type: globalTypes.wallet.preset,
                        payload: {
                            mode: 'tabs',
                            tab: 'deposit'
                        }
                    });
                    
                    ModalsLib.open('wallet');
                }
            }
        }

    }
}

export const logout = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/logout',
    });

    if(response.success) {
        dispatch({
            type: types.logout
        });

        WS.logout();
    }
}