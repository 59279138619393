import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, isSupported as FirebaseIsSupported} from "firebase/messaging";
import API from './api';

export const firebaseConfig = {
    apiKey: "AIzaSyCP_YhicMNytQ78iMpeIAE3FEqUxwW9FsU",
    authDomain: "lucksy-51b87.firebaseapp.com",
    projectId: "lucksy-51b87",
    storageBucket: "lucksy-51b87.firebasestorage.app",
    messagingSenderId: "255777423533",
    appId: "1:255777423533:web:80f79a875cb7435e1b8624",
    measurementId: "G-4Q729Q1XSQ"
}

export const FIREBASE_VAPID_KEY = "BJXwVhHkfql9cAjTH5M5UbMoBWsAHwF7DtwOiA5_udZKi8bnA0C5D4J8lRxhKOJorPAv1g_EkA7Q7wXkkxk3l48";

const app = initializeApp(firebaseConfig);

let messaging = null;

if('Notification' in window)
    messaging = getMessaging(app);

export const isSupported = async() => {
    if('Notification' in window)
        return true;

    return false;
};

export const requestForToken = async() => {
    try {
        return getToken(messaging, {vapidKey: FIREBASE_VAPID_KEY})
        .then(async(currentToken) => {
            if(currentToken)
                return currentToken;
    
            return null;
        }).catch(e => {
            return null;
        });
    } catch(e) {
        return null;
    }
}