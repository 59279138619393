import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/modals/facebook';
import BlueButton from "../../../components/blue-button"
import ModalsLib from '../../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';

export default ({setStage}) => {
    const {profile} = useSelector(Selector);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Facebook Boost</div>
                    <div className="descriptions">Copy the message below and post it on your Facebook page. Pin your post on the wall!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                
                <div className="message" dangerouslySetInnerHTML={{__html: profile.message.replace(/\n/, '').replace(/\n/gi, '<br/>')}}>

                </div>
                <CopyToClipboard text={profile.message} onCopy={e => {
                    toast.dismiss();
                    toast.info(`Message copied to clipboard!`);
                }}>
                    <div className="copy-button">Copy message</div>
                </CopyToClipboard>
            </div>

            <div className="modal-footer">
                <BlueButton onClick={e => {
                    setStage('profile');
                }}>
                    I made a post
                </BlueButton>
            </div>
        </>
    )
}