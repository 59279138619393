import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit_failed';
import ModalsLib from '../../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import BlueButton from '../../components/blue-button';

export default () => {
    const {transaction, game, reason, param} = useSelector(Selector);

    if(!transaction || !game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Deposit failed</div>
                    <div className="descriptions">Your deposit has been cancelled, you can see the reason below</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="tx-amount"><span>$</span>{transaction.amount}</div>
                </div>

                <div className="tx-details">
                    <div className="label">Transaction ID</div>
                    <CopyToClipboard text={`Deposit transaction ${transaction.transactionId} for the amount $${transaction.amount} to login ${transaction.login} in ${game.name}`} onCopy={e => {
                        toast.dismiss();
                        toast.info(`The transaction information has been copied to the clipboard.`);
                    }}>
                        <div className="value">
                            {transaction.transactionId} 
                        
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                    <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                </svg>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>

                {reason === 'user_in_bonus' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Your gaming account is in a bonus session. Complete the bonus session and try to deposit again...</span>
                    </div>
                )}

                {reason === 'store_balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>At the moment, we cannot make a deposit for this amount. Try to make a deposit for a smaller amount or contact technical support to resolve this issue.</span>
                    </div>
                )}

                {reason === 'system' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Funds could not be credited to your game login. Try again later... [<b>{param}</b>]</span>
                    </div>
                )}

                {reason === 'balance' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>You cannot make a deposit into this game because <b>your game balance is more than $2</b>.</span>
                    </div>
                )}

                {reason === 'user_in_game' && (
                    <div className="modal-message warning">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Log out of the game and try to make a deposit again.</span>
                    </div>
                )}
            </div>

            <div className="modal-footer">
                {['balance', 'user_in_bonus', 'user_in_game'].includes(reason) && (
                    <BlueButton onClick={e => ModalsLib.open('changeGame', {
                        handler: game => ModalsLib.open('addcash', {game})
                    })}>
                        Change game
                    </BlueButton>
                )}

                {['store_balance', 'system'].includes(reason) && (
                    <BlueButton onClick={e => window.open('https://t.me/casinos_support_bot', '_blank')}>
                        Live operator
                    </BlueButton>
                )}

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}