import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/pages/freecash/notifications';
import {subscribePushNotifications} from '../../reducers/app/actions';
import BlueButton from '../components/blue-button';
import ModalsLib from '../../lib/modals';

export default () => {
    const {button} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="modal auth notifications animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="title">Push Notifications</div>
                <div className="close" onClick={e => {
                    localStorage.setItem('nmt', Date.now() + (2 * 60 * 60 * 1e3));
                    ModalsLib.close();
                }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="image">
                    <div className="notify"></div>
                </div>

                <div className="sub-title">Enable push notifications</div>
                <div className="text">Stay updated with free games, exclusive bonuses, and important updates! Turn on Push Notifications and never miss out on the best rewards.</div>
            </div>

            <div className="modal-footer">
                <BlueButton active={button} onClick={e => dispatch(subscribePushNotifications(() => {
                    ModalsLib.close();
                }))}>
                    Enable notifications
                </BlueButton>
            </div>
        </div>
    )
}