import {useSelector} from 'react-redux';
import Selector from '../../../../../../selectors/pages/home/facebook_boost';
import ModalsLib from '../../../../../../lib/modals';

export default () => {
    const {isAuth} = useSelector(Selector);

    return (
        <div className="slide facebook size-params">
            <div className="size-params-layer layer0"><div className="facebook-icon"></div></div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    FACEBOOK<br/>BOOST
                </div>
                <div className="descriptions-short">Tell your friends about us on Facebook and get powerful bonuses!</div>
                <div className="details">
                    <div className="button" onClick={e => {
                        if(isAuth)
                            ModalsLib.open('facebook')
                        else
                            ModalsLib.open('signin')
                    }}>More info</div>
                </div>
            </div>
        </div> 
    )
}